let imgHome = <svg width="20" height="16" viewBox="0 0 20 16" fill="#4F4DFF"  xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7917 7.46528L17.7778 5.79861V2.46528C17.7778 2.32639 17.6389 2.1875 17.5 2.1875H16.3889C16.2153 2.22222 16.1111 2.32639 16.1111 2.46528V4.44444L11.2153 0.416667C10.9028 0.138889 10.4514 0 10 0C9.51389 0 9.0625 0.138889 8.75 0.416667L0.173611 7.46528C0.0694444 7.56944 0 7.70833 0 7.88194C0 8.02083 0.0347222 8.15972 0.104167 8.22917L0.486111 8.68056C0.555556 8.78472 0.729167 8.85417 0.902778 8.85417C1.04167 8.85417 1.14583 8.81944 1.25 8.75L2.22222 7.95139V14.4097C2.22222 15.0347 2.70833 15.5208 3.33333 15.5208H7.77778C8.36806 15.5208 8.85417 15.0347 8.88889 14.4097V10.7986H11.1111V14.4097C11.1111 15.0347 11.5972 15.5208 12.2222 15.5208H16.6667C17.2569 15.5208 17.7431 15.0347 17.7778 14.4444V7.95139L18.7153 8.75C18.8194 8.81944 18.9236 8.88889 19.0625 8.88889C19.2361 8.88889 19.4097 8.78472 19.5139 8.68056L19.8611 8.22917C19.9306 8.15972 20 8.02083 20 7.88194C20 7.70833 19.8958 7.56944 19.7917 7.46528ZM16.0764 13.8542H12.7778V10.2431C12.7431 9.65278 12.2569 9.16667 11.6667 9.13194H8.33333C7.70833 9.16667 7.22222 9.65278 7.22222 10.2431V13.8542H3.88889V6.59722L10 1.5625L16.1111 6.59722L16.0764 13.8542Z" />
</svg>

let imgPlay = <svg width="20" height="23" viewBox="0 0 20 23" fill="#00A3FF" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.9603 9.59376L3.21968 0.292479C1.92286 -0.467722 0 0.292479 0 2.12591V20.7285C0 22.4277 1.78871 23.4563 3.21968 22.6066L18.9603 13.3053C20.3466 12.4557 20.3466 10.4434 18.9603 9.59376ZM2.14645 20.2813V2.6178C2.14645 2.39421 2.37004 2.26006 2.54891 2.39421L17.4846 11.2036C17.6635 11.3378 17.6635 11.5613 17.4846 11.6955L2.54891 20.5049C2.37004 20.5943 2.14645 20.5049 2.14645 20.2813Z" />
</svg>

// let imgChartLine = <svg width="20" height="15" viewBox="0 0 20 15" fill="#FF1F00" xmlns="http://www.w3.org/2000/svg">
//     <path d="M4.57031 8.35938C4.80469 8.59375 5.23438 8.59375 5.46875 8.35938L7.5 6.32812L10.7812 9.64844C11.0156 9.88281 11.4453 9.88281 11.6797 9.64844L15.9766 5.35156L17.1484 6.48438C17.7344 7.10938 18.75 6.67969 18.75 5.82031V1.875C18.75 1.5625 18.4375 1.25 18.125 1.25H14.1797C13.3203 1.25 12.8906 2.26562 13.5156 2.85156L14.6484 4.02344L11.25 7.46094L7.92969 4.14062C7.69531 3.90625 7.26562 3.90625 7.03125 4.14062L4.14062 7.03125C3.90625 7.26562 3.90625 7.69531 4.14062 7.92969L4.57031 8.35938ZM19.375 13.125H1.875V0.625C1.875 0.3125 1.5625 0 1.25 0H0.625C0.273438 0 0 0.3125 0 0.625V13.75C0 14.4531 0.546875 15 1.25 15H19.375C19.6875 15 20 14.7266 20 14.375V13.75C20 13.4375 19.6875 13.125 19.375 13.125Z"/>
// </svg>

let imgUser = <svg width="20" height="23" viewBox="0 0 20 23" fill="#FF8171"  xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9732 13.5714C12.6786 13.5714 12.0982 14.2857 10 14.2857C7.85714 14.2857 7.27679 13.5714 5.98214 13.5714C2.67857 13.5714 0 16.2946 0 19.5982V20.7143C0 21.9196 0.9375 22.8571 2.14286 22.8571H17.8571C19.0179 22.8571 20 21.9196 20 20.7143V19.5982C20 16.2946 17.2768 13.5714 13.9732 13.5714ZM17.8571 20.7143H2.14286V19.5982C2.14286 17.4554 3.83929 15.7143 5.98214 15.7143C6.65179 15.7143 7.67857 16.4286 10 16.4286C12.2768 16.4286 13.3036 15.7143 13.9732 15.7143C16.1161 15.7143 17.8571 17.4554 17.8571 19.5982V20.7143ZM10 12.8571C13.5268 12.8571 16.4286 10 16.4286 6.42857C16.4286 2.90179 13.5268 0 10 0C6.42857 0 3.57143 2.90179 3.57143 6.42857C3.57143 10 6.42857 12.8571 10 12.8571ZM10 2.14286C12.3214 2.14286 14.2857 4.10714 14.2857 6.42857C14.2857 8.79464 12.3214 10.7143 10 10.7143C7.63393 10.7143 5.71429 8.79464 5.71429 6.42857C5.71429 4.10714 7.63393 2.14286 10 2.14286Z"/>
</svg>

let imgComment = <svg width="20" height="20" viewBox="0 0 20 20" fill="#2A29B4" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5 0H2.5C1.09375 0 0 1.13281 0 2.5V13.75C0 15.1562 1.09375 16.25 2.5 16.25H6.25V19.5312C6.25 19.8438 6.44531 20 6.71875 20C6.79688 20 6.875 20 6.99219 19.9219L11.875 16.25H17.5C18.8672 16.25 20 15.1562 20 13.75V2.5C20 1.13281 18.8672 0 17.5 0ZM18.125 13.75C18.125 14.1016 17.8125 14.375 17.5 14.375H11.25L10.7422 14.7656L8.125 16.7188V14.375H2.5C2.14844 14.375 1.875 14.1016 1.875 13.75V2.5C1.875 2.1875 2.14844 1.875 2.5 1.875H17.5C17.8125 1.875 18.125 2.1875 18.125 2.5V13.75ZM5 6.875C4.29688 6.875 3.75 7.46094 3.75 8.125C3.75 8.82812 4.29688 9.375 5 9.375C5.66406 9.375 6.25 8.82812 6.25 8.125C6.25 7.46094 5.66406 6.875 5 6.875ZM10 6.875C9.29688 6.875 8.75 7.46094 8.75 8.125C8.75 8.82812 9.29688 9.375 10 9.375C10.6641 9.375 11.25 8.82812 11.25 8.125C11.25 7.46094 10.6641 6.875 10 6.875ZM15 6.875C14.2969 6.875 13.75 7.46094 13.75 8.125C13.75 8.82812 14.2969 9.375 15 9.375C15.6641 9.375 16.25 8.82812 16.25 8.125C16.25 7.46094 15.6641 6.875 15 6.875Z"/>
</svg>

let imgWallet = <svg width="20" height="18" viewBox="0 0 20 18" fill="#33A74A" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5 3.125V2.5C17.5 1.13281 16.3672 0 15 0H3.75C1.64062 0 0 1.67969 0 3.75V13.75C0 15.8594 1.64062 17.5 3.75 17.5H17.5C18.8672 17.5 20 16.4062 20 15V5.625C20 4.25781 18.8672 3.125 17.5 3.125ZM18.125 15C18.125 15.3516 17.8125 15.625 17.5 15.625H3.75C2.69531 15.625 1.875 14.8047 1.875 13.75V3.75C1.875 2.73438 2.69531 1.875 3.75 1.875H15C15.3125 1.875 15.625 2.1875 15.625 2.5V3.75H4.375C4.02344 3.75 3.75 4.0625 3.75 4.375C3.75 4.72656 4.02344 5 4.375 5H17.5C17.8125 5 18.125 5.3125 18.125 5.625V15ZM15 8.75C14.2969 8.75 13.75 9.33594 13.75 10C13.75 10.7031 14.2969 11.25 15 11.25C15.6641 11.25 16.25 10.7031 16.25 10C16.25 9.33594 15.6641 8.75 15 8.75Z" />
</svg>

let headerUserPageImages = [imgHome,imgUser,imgWallet,imgPlay,imgComment]
export { headerUserPageImages }